<script setup lang="ts">
import { ref } from 'vue';
import { NavigationLink } from '~/types/navigationLink';

interface Props {
  placeholder: string;
  items: Array<NavigationLink>;
}

interface Emits {
  (e: 'select', id: NavigationLink): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const isOpen = ref(false);

function toggleView() {
  isOpen.value = !isOpen.value;
}

function itemSelect(item: NavigationLink) {
  isOpen.value = false;
  emit('select', item);
}
</script>

<template>
  <div class="v-dropdown">
    <VButton
      remove-icon
      class="v-dropdown__button"
      @click="toggleView"
    >
      {{ props.placeholder }}
    </VButton>
    <div
      v-if="isOpen"
      class="v-dropdown__items"
    >
      <a
        v-for="item in props.items"
        :href="item.route"
        class="v-dropdown__items--item"
        @click.prevent="itemSelect(item)"
      >
        {{ item.title }}
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.v-dropdown {
  z-index: 20;
}

.v-dropdown__button,
.v-dropdown__items {
  width: 250px;
}

.v-dropdown__items {
  @include font-style-400-14-120;
  position: absolute;
  padding: 8px 0;
  border-radius: 10px;
  margin-top: 5px;
  box-shadow: $shadow-outside-icon;
  background: $color-blue;
  color: $color-secondary;
}

.v-dropdown__items--item {
  display: block;
  text-decoration: none;
  padding: 16px 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.v-dropdown__items--item:hover {
  background: $color-blue-2;
}
</style>
